:root {
	/* ------- colors ------- */
	--primary-color: #27272a;
	--secondary-color: #65656d;
	--tertiary-color: #acacb4;
	--quaternary-color: #e4e4e7;
	--link-color: #14b8a6;

	--primary-color-dark: #d9d9e8;
	--secondary-color-dark: #a9a9b5;
	--tertiary-color-dark: #69696d;
	--quaternary-color-dark: #1f1f1f;

	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}
