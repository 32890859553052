@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 130px;
}

.homepage-container {
	padding-top: 25px;
}

.homepage-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.homepage-first-area-left-side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 70%;
}

.homepage-title {
	width: 85% !important;
}

.homepage-subtitle {
	width: 90% !important;
}

.homepage-logoTitle {
	max-width: 300px;
}

.homepage-first-area-right-side {
	display: flex;
	align-items: center;
}

.homepage-image-container {
	width: 370px;
	height: 370px;
}

.homepage-image-wrapper {
	overflow: hidden;
	/* border-radius: 10%; */
	/* transform: rotate(3deg); */
	display: flex;
	place-content: center;
}

.homepage-image-wrapper img {
	width: 100%;
	max-width: 200px;
	height: 100%;
}

.homepage-image-0 {
	opacity: 0;
	/* -webkit-transition: all 2s ease; 
	-moz-transition: all 2s ease; 
	-ms-transition: all 2s ease; 
	-o-transition: all 2s ease; 
	transition: all 2s ease;  */
}

.homepage-image-1 {
	opacity: 1;
	-webkit-transition: all 2s ease; 
	-moz-transition: all 2s ease; 
	-ms-transition: all 2s ease; 
	-o-transition: all 2s ease; 
	transition: all 2s ease; 
}

.homepage-support-button {
    	background: #737373;
    	padding: 8px;
    	color: white;
    	text-decoration: none !important;
}

.homepage-support-button:link {

    	text-decoration: none !important;
}
/* .carousel-images {
	position: relative;
	border-radius: 10px;
	height: 400px;
	max-width: 650px;
	margin: auto;
	overflow: hidden;
  }
  .carousel-images img {
	width: 99%;
	height: 99%;
	border-radius: 8px;
	border: #ff00008e solid 2px;
  }
  .slide_direction {
	display: flex;
	justify-content: space-between;
  }
  .left,
  .right {
	background-color: #fb666675;
	color: #fff;
	padding: 10px 8px 8px 13px;
	margin: 0 20px;
	border-radius: 50%;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto 10px;
	height: 25px;
	width: 25px;
  }
  .left {
	left: 0;
  }
  .right {
	right: 0;
  }
  .carousel-indicator {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	gap: 20px;
  }
  .dot {
	background-color: #333;
	width: 15px;
	height: 15px;
	border-radius: 50%;
  } */

.homepage-socials {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 30px;
}

.homepage-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.homepage-social-icon:hover {
	color: var(--primary-color);
}

.homepage-after-title {
	display: flex;
}

.homepage-projects {
	flex-basis: 300px;
	padding-top: 40px;
}

.homepage-articles {
	flex-basis: 900px;
	padding-top: 30px;
	margin-left: -30px;
}

.homepage-article {
	padding-bottom: 5px;
}

.homepage-works {
	flex-basis: 600px;
	padding-top: 30px;
}

@media (max-width: 1024px) {
	.homepage-first-area {
		flex-direction: column;
	}

	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column; /* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.homepage-title {
		width: 100% !important;
		margin-top: 20px;
		order: 2;
	}

	.homepage-subtitle {
		width: 100% !important;
		margin-top: 10px;
		order: 3;
	}

	.homepage-first-area-right-side {
		width: 100%;
		order: 1;
	}

	.homepage-image-container {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-left: 10px;
		order: 1;
	}

	.homepage-after-title {
		flex-direction: column;
	}

	.homepage-projects {
		flex-basis: auto;
	}

	.homepage-articles {
		flex-basis: auto;
	}

	.homepage-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}
}
